<template>
  <div class="answer-page">

    <!-- 第一页-->
    <div class="page-index" v-show="step === 1">
      <div class="quarter-title">
        <div class="quarter-top">2023季度</div>
        <div class="quarter-bottom"><span class="name">{{innerData.lottery.name}}</span>有奖问答</div>
        <div class="circle-triangle"></div>
      </div>

      <div class="answer-description">
        <div class="circle-top">活动说明</div>
        <div class="title">
          <p>请详细阅读下方说明</p>
          <p>按照要求完成作答</p>
        </div>

        <div class="desc" v-show="innerData.question.lotteryCorrectCount >0">答对{{innerData.question.lotteryCorrectCount}}题即可参与抽奖领取丰厚奖品</div>
        <div class="desc" v-show="innerData.question.lotteryCorrectCount ==0 || innerData.question.lotteryCorrectCount==null">参与有奖问答即可领取丰厚奖品</div>

        <div class="description">
          <pre>{{innerData.lottery.description}} </pre>
        </div>
        <!--div class="items">
          <div class="item-row">
            <div class="item-number">1</div>
            <div class="item-content">
              <p class="item-title">抽奖答题规则</p>
              <p class="item-text"></p>
            </div>
          </div>
          <div class="item-row">
            <div class="item-number">2</div>
            <div class="item-content">
              <p class="item-title">Qui in ea voluptate velit</p>
              <p class="item-text">totam rem aperiam, eaque ipsa quae ab illo inventore</p>
            </div>
          </div>
          <div class="item-row">
            <div class="item-number">3</div>
            <div class="item-content">
              <p class="item-title">laudantium, totam rem aperiam</p>
              <p class="item-text">totam rem aperiam, eaque ipsa quae ab illo inventore</p>
            </div>
          </div>>
        </div-->

        <div class="agree">
          <a-radio-group v-model="index_agree_radio">
            <a-radio :value="true">我已经阅读并了解了答题说明</a-radio>
          </a-radio-group>
        </div>

        <div class="start-button" @click="handleStartAnswer">点击开始答题</div>
      </div>
    </div>
    <!-- 第二页-->
    <div class="page-answer" v-show="step === 2">
      <div class="page-title">
        <span>2023季度</span>
        <span class="name">{{innerData.lottery.name}}有奖问答</span>
      </div>

      <div class="problem-title">
        <div class="problem-action">
          <div class="problem-number">第{{currentIndex + 1}}/{{questionData.length}}题</div>
          <div v-if="questionData[currentIndex].type == 1" class="problem-type">单选题</div>
          <div v-else class="problem-type">多选题</div>
        </div>

        <div class="problem-text">
          <div class="">{{questionData[currentIndex].title}}</div>
        </div>
      </div>

      <div class="problem-items">
        <div :class="item.active == true ? 'item active' : 'item'" @click="handleSelectOption(index)" v-for="(item, index) in questionData[currentIndex].options" :key="index">
          <div class="number">{{item.character}}</div>
          <div class="text">{{item.text}}</div>
        </div>
      </div>

      <div class="switch-problem">
        <div class="last-button" v-if="currentIndex == 0" @click="handleLastPage">上一页</div>
        <div class="last-button" v-else @click="handleLastQuestion">上一题</div>
        <div class="next-button" v-if="(currentIndex+1) == questionData.length" @click="handleSubmit">提交</div>
        <div class="next-button" v-else @click="handleNextQuestion">下一题</div>
      </div>
    </div>
    <!-- 第三页 -->
    <div class="page-success" v-show="step === 3">
      <div class="success-title">恭喜您</div>
      <img class="success-image" src="@/static/images/lottery-answer-success.jpg">
      <div class="success-text">
        <p>您完成了所有的问答</p>
        <p>并且成绩优异</p>
      </div>
      <div class="success-prompt">
        尽快去参与抽奖活动吧
      </div>

      <div class="lottery-button" @click="handleGoLottery">点击去抽奖</div>
    </div>

    <a-modal
        width="70%"
        :visible="roomVisible"
        :closable="false"
    >
      <div class="room_qrcode">
        <img :src="innerData.qrcode_url" alt="" />
        <span style="margin-top: 10px; font-size: 12px; color: #9b9b9b">长按二维码，加入群聊</span>
      </div>

      <template slot="footer">
        <a-button size="large" style="background-color: #000000; color: #ffffff" @click="handleCancel" block>关闭</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {answerDataApi} from "@/api/lottery";
export default {
  name: "answer-components",
  props: {
    // 用户微信信息
    userInfo: {
      type: Object,
      default: function() {
        return {};
      }
    },
    // 客户数据
    clientData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      step: 1,
      innerData: this.clientData, // 内部接收数据
      roomVisible: false,
      index_agree_radio: false,
      currentIndex: 0,
      questionData: [],
      lotteryConfig: {},
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
        this.questionData = this.innerData.question.content ?? [];
        // 抽奖配置
        this.lotteryConfig = {
          condition: this.innerData.question.lotteryCondition ?? 0,
          correctCount: this.innerData.question.lotteryCorrectCount
        };
        if (this.innerData.is_answer) {
          this.step += 1;
        }

    },
    // 开始答题
    handleStartAnswer() {
      if(this.index_agree_radio == false) {
        return this.$message.error('请阅读并确认答题说明');
      }

      // 群聊判断
      if(this.innerData.question.lotteryRoom.length > 0 &&  this.innerData.is_join_room == false) {
        this.roomVisible = true
        return
      }

      this.step += 1;
    },
    // 上一页面
    handleLastPage() {
      this.step = 1;
    },
    // 上一题
    handleLastQuestion() {
      this.currentIndex -= 1;
    },
    // 下一题
    handleNextQuestion() {
      let selectNumber = this.questionData[this.currentIndex].options.reduce((num, item) => num + Number(item.active ?? 0), 0);
      // 未选择答案
      if (selectNumber == 0) {
        return this.$message.error('请选择答案');
      }
      this.currentIndex += 1;
    },
    // 选中答案
    handleSelectOption(index) {
      this.questionData[this.currentIndex].options = this.questionData[this.currentIndex].options.map((item, i) => {
       
        if(this.questionData[this.currentIndex].type==1){
          item.active = i == index;
        return item;
        }else{
          if(i == index){
            item.active = item.active?false:true;
          }else{
            let nothing= item.active ;
          if (typeof nothing === 'undefined') {
            item.active =false;
          }
          }
          return item;
        }
       
      })
    
    },
    // 提交答案
    handleSubmit() {
      debugger
      let selectNumber = this.questionData[this.currentIndex].options.reduce((num, item) => num + Number(item.active ?? 0), 0);
      // 未选择答案
      if (selectNumber == 0) {
        return this.$message.error('请选择答案');
      }

      let optionsAnswers = this.questionData.map((item, index) => {
        // 正确答案数量（后台配置）
        let correctNumber = item.options.reduce((num, item) => num + (item.correct ? 1 : 0), 0);
        // 正确答案数量（用户选择）
        let selectCorrectNumber = item.options.reduce((num, option) => num + Number((option.active ?? 0) && option.correct), 0);
        return correctNumber == selectCorrectNumber;
      });

      let correctAnswerNumber = optionsAnswers.reduce((total, val) => total + Number(val), 0);

      // 验证正确答案数量
      if (this.lotteryConfig.condition == 2 && correctAnswerNumber < this.lotteryConfig.correctCount) {
        return this.$message.error('答对' + this.lotteryConfig.correctCount + '题才能参与抽奖');
      }

      let params = {
        lottery_id:  this.$route.query.id,
        union_id: this.userInfo.unionid,
        answer_content: this.questionData
      }

      answerDataApi(params).then((res) => {

        this.step += 1;
      })

    },
    handleGoLottery(){
      this.$parent.handleCloseAnswerPage();
    },
    handleCancel() {
      this.roomVisible = false
    },
  }
}
</script>

<style scoped lang="less">
.answer-page {
  width: 100vw;
  height: 100vh;
  background-color: #ff5636;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(0deg, #6eff72 0%, #5cd6d6 100%);
  background-size: cover;
  position: relative;
  overflow-y: auto;
  padding: 0 5vw;
}

.page-index {
  margin: 5vw 0;
  .quarter-title {
    width: 90vw;
    height: 32vw;
    background-color: #ffffff;
    border-radius: 16vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .circle-triangle {
    width: 0;
    height: 0;
    border-top: 12vw solid #ffffff;
    border-left: 7vw solid transparent;
    border-right: 7vw solid transparent;
    position: absolute;
    bottom: -5vw;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 99;
  }

  .quarter-top {
    font-size: 7vw;
    line-height: 10vw;
    color: #020202;
  }

  .quarter-bottom {
    font-size: 5vw;
    font-weight: bold;
    line-height: 12vw;
    color: #020202;

    .name {
      color: #58cc59;
    }
  }

  .answer-description {
    width: 90vw;
    height: 140vw;
    background-color: #ffffff;
    border-radius: 7vw;
    border: solid 1vw #000000;
    margin-top: 8vw;
    position: relative;

    .circle-top {
      width: 75vw;
      height: 13vw;
      line-height: 13vw;
      background-color: #000000;
      border-radius: 7vw;
      position: absolute;
      top: -6.5vw;
      left: 0;
      right: 0;
      margin: auto;
      color: #ffffff;
      text-align: center;
    }

    .title {
      font-size: 4vw;
      line-height: 3vw;
      font-weight: bold;
      color: #020202;
      text-align: center;
      margin-top: 14vw;
    }

    .desc {
      font-weight: normal;
      line-height: 6vw;
      letter-spacing: 0vw;
      color: #020202;
      text-align: center;
    }

    .items, .description {
      width: 72vw;
      height: 64vw;
      margin: 8vw auto 0;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;
    }

    .description {
      padding: 6vw 2vw 0 3vw;
      font-size: 3vw;
    }


    .item-row {
      display: flex;
      margin-top: 6vw;
    }

    .item-number {
      width: 10vw;
      height: 8vw;
      line-height: 8vw;
      border-radius: 100%;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      background-color: #000000;
    }

    .item-content {
      margin-left: 16px;
      font-size: 3vw;

      p {
        margin: 0;
      }
    }

    .item-title {
      font-weight: bold;
    }

    .agree {
      margin: 3vw 0;
      text-align: center;

      .ant-radio-wrapper {
        font-size: 12px;
      }
    }

    .start-button {
      width: 75vw;
      height: 13vw;
      line-height: 13vw;
      background-color: #000000;
      border-radius: 7vw;
      margin: 4vw auto;
      text-align: center;
      color: #fff;
    }
  }
}

.page-answer {
  width: 90vw;
  height: 187vw;
  background-color: #ffffff;
  border-radius: 5vw;
  margin: 4vw 0;

  .page-title {
    text-align: center;
    margin-top: 7vw;
    font-size: 4vw;
    line-height: 6vw;
    color: #020202;

    .name {
      color: #58cc59;
      margin-left: 18px;
    }
  }

  .problem-title {
    width: 75vw;
    height: 37vw;
    border-radius: 5vw;
    border: solid 1vw #000000;
    margin: 5vw auto;
  }

  .problem-action {
    width: 75vw;
    height: 9vw;
    padding: 0 2vw;
    background-color: #000000;
    border-radius: 10vw;
    margin-left: -1vw;
    display: flex;
    margin-top: -1vw;
    color: #ffffff;
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
  }

  .problem-type {
    width: 18vw;
    height: 6vw;
    line-height: 6vw;
    background-color: #ffffff;
    border-radius: 7vw;
    color: #000000;
    text-align: center;
  }

  .problem-text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 2vw 4vw 0;
    font-size: 4vw;
    height: 20vw;
    line-height: 6vw;
    color: #020202;

  }

  .problem-items {
    margin: 6vw;

    .item {
      height: 14vw;
      padding: 0 3vw;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: 1vw 0;
    }

    .number {
      margin-right: 3vw;
      width: 7vw;
      height: 7vw;
      line-height: 7vw;
      border-radius: 100%;
      color: #ffffff;
      text-align: center;
      background-color: #000000;
    }

    .text {
      width: 62vw;
      font-size: 3vw;
      font-weight: bold;
      line-height: 4vw;
      color: #020202;
    }

    .active {
      background-image: linear-gradient(0deg,
      #6eff72 0%,
      #5cd6d6 100%);
      border-radius: 7vw;
    }
  }

  .switch-problem {
    display: flex;
    justify-content: center;
    margin-top: 8vw;
    color: #ffffff;

    div {
      margin: 2vw 2vw;
      width: 38vw;
      line-height: 12vw;
      text-align: center;
      background-color: #000000;
      border-radius: 7vw;
      border: solid 1vw #000000;
    }
  }
}

.page-success {
  width: 90vw;
  height: 187vw;
  background-color: #ffffff;
  border-radius: 5vw;
  margin: 4vw 0;
  text-align: center;

  .success-title {
    font-size: 8vw;
    font-weight: bold;
    line-height: 6vw;
    color: #020202;
    text-align: center;
    margin-top: 16vw;
  }

  .success-image {
    width: 48vw;
    height: 42vw;
    margin-top: 4vw;
  }

  .success-text {
    font-size: 4vw;
    line-height: 2vw;
    color: #020202;
    margin-top: 6vw;
  }

  .success-prompt {
    font-size: 4vw;
    line-height: 2vw;
    color: #020202;
    margin-top: 6vw;
  }

  .lottery-button {
    width: 75vw;
    height: 13vw;
    line-height: 13vw;
    text-align: center;
    color: #ffffff;
    background-color: #000000;
    border-radius: 7vw;
    margin: 16vw auto;
  }
}

.room_qrcode {
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: 100%;
  }
}
</style>