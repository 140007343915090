<template>
  <div>
    <answerComponent :user-info="weChatUserInfo" :client-data="clientData" v-if="pageIndex == 1"></answerComponent>
    <lotteryComponent :user-info="weChatUserInfo" :client-data="clientData" v-if="pageIndex == 2"></lotteryComponent>
  </div>
</template>

<script>
import {contactDataApi, openUserInfoApi} from "@/api/lottery";

import answerComponent from "./components/answer";
import lotteryComponent from "./components/lottery";

export default {
    components: {
      answerComponent,
      lotteryComponent
    },
    data() {
        return {
          pageIndex: 0,               // 当前显示页面
          weChatUserInfo: {},         // 用户微信信息
          clientData: {},             // 请求数据
          url: window.location.href,  // 当前URL
        }
    },
    created() {
      //  获取参数
      this.id     = this.$route.query.id;
      this.source = this.$route.query.source;
      // 获取数据
      this.getOpenUserInfo();
    },
    methods: {
      getOpenUserInfo() {
        let that = this;
        openUserInfoApi({id: that.id}).then((res) => {
          if (res.data.openid === undefined) {
            let redirectUrl = '/auth/lottery?id='+ that.id +'&target=' + encodeURIComponent(that.url);
            that.$redirectAuth(redirectUrl);
          }

          this.weChatUserInfo = res.data;
          // 获取客户端
          this.getClientData();
        });
      },
      //  获取客户数据
      getClientData() {
        let params = {
          id:       this.id,
          union_id: this.weChatUserInfo.unionid,
          nickname: this.weChatUserInfo.nickname,
          avatar:   this.weChatUserInfo.headimgurl,
          city:     this.weChatUserInfo.city,
          source:   this.source
        }

        contactDataApi(params).then((res) => {
          document.title = res.data.lottery.name;
          this.clientData = res.data;
          // 未开启抽奖问题
          let question = res.data.question || [];
          this.pageIndex = (question.length == 0) ? 2 : 1;
          //如果已经答题直接跳转
          this.pageIndex = (res.data.is_answer) ? 2 : 1;
        })
      },
      handleCloseAnswerPage() {
        this.pageIndex = 2;
      }
    }
}
</script>

<style scoped lang="less">

</style>
